import './bootstrap';
import {createApp, defineAsyncComponent, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import VueDraggableResizable from 'vue-draggable-resizable'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {current_layout, current_layout_component, is_mounted} from "./helper/App";
import * as Sentry from '@sentry/vue';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import './logout-manager';

// Fonction qui charge le layout. L'objectif est de ne pas charger le layout par défaut sur toutes les pages par défaut. La vue Blade définit le template par
// défaut à utiliser pour la page. On charge le layout de manière asynchrone par rapport à ce que la vue Blade a défini.
// Cela est surtout utile pour la route login : la route login ne charge pas le layout par défaut. Ainsi, un utilisateur non connecté ne peut pas inspecter
// le code et voir le layout après le login.
async function loadDefaultLayout() {
	if (current_layout.value === window.layout && current_layout_component.value) return current_layout_component.value;

	current_layout.value = window.layout;
	if (current_layout.value === 'default') {
		current_layout_component.value = defineAsyncComponent(() => import(`../../templates/layouts/defaultLayout.vue`));
		return current_layout_component.value;
	}
	current_layout_component.value = defineAsyncComponent(() => import(`../../templates/layouts/emptyLayout.vue`));
	return current_layout_component.value
}


createInertiaApp({
	resolve: async (name) => {
		const defaultLayout = await loadDefaultLayout();
		const page = resolvePageComponent(`../../templates/pages/${name}.vue`, import.meta.glob('../../templates/pages/**/*.vue'));
		page.then((module) => {
			module.default.layout = module.default.layout || defaultLayout;
		});

		return page;
	},
	setup({el, App, props, plugin}) {
		const app = createApp({render: () => h(App, props)});

		Sentry.init({
			app: app,
			dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
			tracesSampleRate: 1.0,
			replaysOnErrorSampleRate: 0.5,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
		});

		app.use(plugin)
			.use(FloatingVue)
			.use(Toast, {
				toastClassName: 'custom',
				bodyClassName: ['custom'],
				transition: "Vue-Toastification__fade",
				maxToasts: 4,
				newestOnTop: true,
				position: "bottom-right",
			})
			.component('vue-draggable-resizable', VueDraggableResizable)
			.mount(el);

		is_mounted.value = true;
	},
	progress: false,
});
