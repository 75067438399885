import {DsUser} from "./helper/User/DsUser.ts";

function isRunningStandalone() {
	return (window.matchMedia('(display-mode: standalone)').matches);
}

let is_checking = false;
function checkForLogout() {
	if(is_checking){
		return;
	}
	is_checking = true;
	setTimeout(() => {
		is_checking = false;
	}, 1000);

	if (!isRunningStandalone() || !DsUser.isAuth()){
		return;
	}

	const today = new Date().toISOString().split('T')[0];
	const lastOpenedDate = localStorage.getItem('lastOpenedDate');

	if (!lastOpenedDate || lastOpenedDate === '') {
		localStorage.setItem('lastOpenedDate', today);
		return;
	}

	if (lastOpenedDate && lastOpenedDate !== today && lastOpenedDate !== 'NO_REDIRECT') {
		localStorage.setItem('lastOpenedDate', today);
		window.location.href = '/logout';
	} else {
		localStorage.setItem('lastOpenedDate', today);
	}
}

checkForLogout();

setInterval(checkForLogout, 1000 * 60 * 10);


document.addEventListener('visibilitychange', function () {
	checkForLogout();
});
window.addEventListener('pageshow', function (event) {
	checkForLogout();
});

window.addEventListener('focus', function () {
	checkForLogout();
});

window.addEventListener('blur', function () {
	checkForLogout();
});
